const FooterBox = () => {
    const boxes = [
        { "h1": "Ayo bergabung!", "p": "Untuk informasi lebih lanjut bisa menghubungi customer@probo.id." },
        { "h1": "Alamat", "p": "Perum Susukan Grabag Magelang Jawa Tengah Indonesia." },
        { "h1": "Promosi", "p": "Promo berlaku sampai akhir tahun 2020. Segera lakukan pendaftaran!" }
    ]
    return (
        <div className="footer-box boxes">
            {
                boxes.map((item, index) => (
                    <div className={ `box box-${index}` } key={ `footer-box-${index}` }>
                        <h1 key={ `footer-box-h1-${index}` }>
                            { item.h1 }
                        </h1>
                        <p key={ `footer-box-p-${index}` }>
                            { item.p }
                        </p>
                    </div>
                ))
            }
        </div>
    )
};

export default FooterBox;