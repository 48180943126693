import { Link } from 'react-router-dom';
import { Link as LinkScroll } from "react-scroll";

const FooterNote = () => (
    <div className="footer-note boxes">
        <nav className="box foot-link">
            <LinkScroll activeClass="active"
                to="header-probo"
                spy={ true }
                smooth={ true }
                offset={ -170 }
                duration={ 500 }
            >
                <Link to="/">
                    <li key={ `footer-note-li-1` }>Home</li>
                </Link>
            </LinkScroll>
            <LinkScroll activeClass="active"
                to="header-probo"
                spy={ true }
                smooth={ true }
                offset={ -170 }
                duration={ 500 }
            >
                <Link to="/kontak">
                    <li key={ `footer-note-li-2` }>Kontak</li>
                </Link>
            </LinkScroll>
            <LinkScroll activeClass="active"
                to="header-probo"
                spy={ true }
                smooth={ true }
                offset={ -170 }
                duration={ 500 }
            >
                <Link to="/client">
                    <li key={ `footer-note-li-3` }>Client</li>
                </Link>
            </LinkScroll>
        </nav>
        <div className="box copyright">
            © 2022 Probo.id
        </div>
    </div>
);

export default FooterNote;
