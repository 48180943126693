import FooterBox from './FooterBox';
import FooterNote from './FooterNote';

const FooterProbo = () => (
    <footer className="footer">
        <FooterBox />
        <FooterNote />
    </footer>
);

export default FooterProbo;