import logo from '../logo.svg';
import Nav from './Nav';
import { Link } from 'react-router-dom';

const HeaderProbo = () => (
    <header className="header-probo" id="header-probo">
        <div className="logo">
            <div>
                <Link to="/">
                    <img src={ logo } alt="probo" />
                </Link>
            </div>
        </div>
        <div className="nav-link">
            <Nav />
        </div>
        <div className="btn-client">
            <Link to="/client">
                <h3>
                    Client
                </h3>
            </Link>
        </div>
    </header>
);


export default HeaderProbo;