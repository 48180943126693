import './styles/App.css';
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HeaderProbo from './components/HeaderProbo';
import FooterProbo from './components/FooterProbo';
import Loader from './components/Loader';

const Slideshow = lazy(() => import('./components/Slideshow'));
const Home = lazy(() => import('./components/Home'));
const Client = lazy(() => import('./components/Client'));
const About = lazy(() => import('./components/About'));
const Contact = lazy(() => import('./components/Contact'));
const Product = lazy(() => import('./components/Product'));
const Service = lazy(() => import('./components/Service'));
const NotFound = lazy(() => import('./components/NotFound'));

function App() {
  return (
    <Router>
      <div className="App">
        <HeaderProbo />
        <Switch>
          <Route path="/" exact >
            <Suspense fallback={ <Loader /> }>
              <Slideshow />
              <Home />
            </Suspense>
          </Route>
          <Route path="/client" >
            <Suspense fallback={ <Loader /> }>
              <Client />
            </Suspense>
          </Route>
          <Route path="/tentang" >
            <Suspense fallback={ <Loader /> }>
              <About />
            </Suspense>
          </Route>
          <Route path="/kontak" >
            <Suspense fallback={ <Loader /> }>
              <Contact />
            </Suspense>
          </Route>
          <Route path="/produk" >
            <Suspense fallback={ <Loader /> }>
              <Product />
            </Suspense>
          </Route>
          <Route path="/layanan"  >
            <Suspense fallback={ <Loader /> }>
              <Service />
            </Suspense>
          </Route>
          <Route path="/*"  >
            <Suspense fallback={ <Loader /> }>
              <NotFound />
            </Suspense>
          </Route>
        </Switch>
        <FooterProbo />
      </div>
    </Router>
  );
}

export default App;
