import { Link } from 'react-router-dom';
import { useState } from 'react';

const Nav = () => {
    const [navigation, setNavigation] = useState(false);
    const toggle = () => {
        setNavigation((prev) => !prev)
    }
    const turnOff = () => {
        setNavigation(false)
    }
    return (
        <nav>
            <div className={ navigation ? 'hamburger open' : 'hamburger' } onClick={ toggle }>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <div className={ navigation ? 'spagheti open' : 'spagheti' } onClick={ turnOff }>
                <ul>
                    <li>
                        <Link to="/layanan" >
                            Layanan
                        </Link>
                    </li>
                    <li>
                        <Link to="/produk" >
                            Produk
                        </Link>
                    </li>
                    <li>
                        <Link to="/tentang" >
                            Tentang
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Nav;